import React from 'react';
import { Helmet } from 'react-helmet';
import { string, arrayOf } from 'prop-types';
import favicon16 from '../../images/favicon/favicon-16.png';
import favicon32 from '../../images/favicon/favicon-32.png';
import favicon64 from '../../images/favicon/favicon-64.png';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({
  title: titleProp,
  author: authorProp,
  description: descriptionProp,
  image: imageProp,
  keywords: keywordsProp,
  lang: langProp,
}) => {
  const {
    defaultTitle,
    titleTemplate,
    title: titleMeta,
    author: authorMeta,
    description: descriptionMeta,
    image: imageMeta,
    keywords: keywordsMeta,
    lang: langMeta,
  } = useStaticQuery(query).site.siteMetadata.seo;

  const title = titleProp || titleMeta;
  const author = authorProp || authorMeta;
  const description = descriptionProp || descriptionMeta;
  const image = imageProp || imageMeta;
  const keywords = keywordsProp || keywordsMeta;
  const lang = langProp || langMeta;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={titleTemplate}
      defaultTitle={defaultTitle}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat({
        name: `keywords`,
        content: keywords.join(','),
      })}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${favicon16}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${favicon32}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '64x64',
          href: `${favicon64}`,
        },
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: `${favicon64}`,
        },
      ]}
    />
  );
};

Seo.propTypes = {
  title: string,
  author: string,
  description: string,
  image: string,
  lang: string,
  keywords: arrayOf(string),
};

export default Seo;

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        seo {
          lang
          titleTemplate
          keywords
          image
          defaultTitle
          description
          author
        }
        companyInfo {
          address
          email
          name
        }
      }
    }
  }
`;
